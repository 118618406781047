<template>
  <div
    v-if="loading && !company"
    v-loading="loading"
    class="loading"
  />
  <router-view
    v-else-if="company"
    :company="company"
    :loading-company="loading"
    @refresh="fetchCompany"
  />
  <div v-else>
    Sorry, that company doesn't seem to exist.
  </div>
</template>

<script>
/**
 * BaseCompany
 *
 * Fetches company object from api and exposes to all child routes.
 *
 * Events:
 *
 * @refresh: re-fetch company data from the api
 */
import RooofAPI from '@/services/api/rooof'

export default {
  name: 'BaseCompany',
  /**
   * React to param changes to refresh the company data.
   */
  beforeRouteUpdate (to, from, next) {
    if (to.params.cid !== from.params.cid) {
      this.fetchCompany(to.params.cid)
    }
    next()
  },
  data () {
    return {
      company: null,
      loading: true
    }
  },
  created () {
    this.fetchCompany()
  },
  methods: {
    /**
     * Fetch the full company object from the api.
     *
     * @param {Number} [id] - company id to fetch, defaults to current route param
     */
    async fetchCompany (id = this.$route.params.cid) {
      try {
        this.loading = true
        this.company = await RooofAPI.companies.retrieve(id)
      } catch (err) {
        if (err.response && err.response.status === 404) {
          return
        }
        throw err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.loading {
  padding: 5em;
}
</style>
